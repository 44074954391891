<template>
    <div class="search-box f-c-b">
        <div class="logo f-c-c">
            <img src="@/assets/logo2.png" alt="">
            <img style="height: 38px;width: 78px;margin-left: 20px;" src="@/assets/shiliao.png" alt="">
        </div>
        <div class="search f-c-e">
            <div class="search-city" v-if="typeName == 'address'">
                <CascaderCity :city="city" @handleChangeCity="handleChangeCity"></CascaderCity>
            </div>
            <div class="search-options">
                <span @click="typeNameChange('product')" :class="typeName == 'product' ? 'pitchOn' : 'NopitchOn'">搜商品</span>
                <span style="margin:0 30px;" @click="typeNameChange('firm')"
                    :class="typeName == 'firm' ? 'pitchOn' : 'NopitchOn'">搜企业</span>
                <span @click="typeNameChange('address')" :class="typeName == 'address' ? 'pitchOn' : 'NopitchOn'">搜地址</span>
            </div>
            <div v-if="typeName == 'product'" class="f-c search-input">
                <el-select class="select-box" v-model="select" placeholder="请选择" @change="changeProductType">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
                <input type="text" placeholder="请输入商品名称" v-model.trim="inputText" @focus="inputFocus" @blur="inputBlur"
                    @keyup.enter="search" maxlength="10" />
                <el-button class="search-icon" @click="handleImageUploadBox"><i class="el-icon-camera"></i></el-button>
                <el-button class="search-icon2" @click="goToColourSearch"><img style="height: 22px;width: 22px;"
                        src="@/assets/colorSearchIcon.png" alt=""></el-button>
            </div>
            <div v-else-if="typeName == 'firm'" class="f-c search-input2">
                <input type="text" placeholder="请输入企业名称" v-model.trim="inputText" @focus="inputFocus" @blur="inputBlur"
                    @keyup.enter="search" maxlength="10" />
            </div>
            <div v-else class="f-c search-input2">
                <input type="text" placeholder="请输入地址(不含省/市/区)" v-model.trim="inputText" @focus="inputFocus"
                    @blur="inputBlur" @keyup.enter="search" maxlength="10" />
            </div>
            <el-button class="search-icon3" @click="search" icon="el-icon-search"></el-button>
        </div>

        <!-- 历史记录 typeName == 'product' ? width: 380px : width: 490px-->
        <div class="search-container" v-show="showHistoryBox">
            <div class="input-history" :style="{ width: typeName == 'product' ? '380px' : '490px' }">
                <div class="f-c-b">
                    <span>历史记录</span>
                    <span class="delete-history" @click="deleteHistoryList"><i class="el-icon-delete"></i></span>
                </div>
                <div class="history f-c">
                    <template v-if="historyList.length && typeName == 'product'">
                        <el-tag class="history-item" v-for="(item, index) of historyList" :key="index" closable type="info"
                            @close="handleClose(item)" @click="historySearch(item)">{{ item }}</el-tag>
                    </template>
                    <template v-else-if="firmhistoryList.length && typeName == 'firm'">
                        <el-tag class="history-item" v-for="(item, index) of firmhistoryList" :key="index" closable
                            type="info" @close="firmhandleClose(item)" @click="historySearch(item)">{{ item }}</el-tag>
                    </template>
                    <template v-else-if="addresshistoryList.length && typeName == 'address'">
                        <el-tag class="history-item" v-for="(item, index) of addresshistoryList" :key="index" closable
                            type="info" @close="addresshandleClose(item)" @click="historySearch(item)">{{ item }}</el-tag>
                    </template>
                    <div v-else class="history-no">暂无历史记录</div>
                </div>
            </div>
        </div>
        <!-- 图片搜索 -->
        <ImageUpload @closeUploadBox="closeUploadBox" v-show="showImageUploadBox" :imageUploadTrends="imageUploadTrends"
            :key="new Date().getTime()">
        </ImageUpload>
    </div>
</template>

<script>
export default {
    name: '',
    components: {
        ImageUpload: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue')
    },
    data() {
        return {
            inputText: '',
            city: [],
            searchResultPage: 1,//搜索结果页
            inputState: '',
            showHistoryBox: false,//是否显示历史记录盒子
            historyList: [],// 商品历史记录盒子
            firmhistoryList: [], // 企业历史记录盒子
            addresshistoryList: [], // 地址历史记录盒子
            showImageUploadBox: false,//展示图片上传盒子
            options: [],
            select: '',
            typeName: 'product',
            imageUploadTrends: {
                'position': 'absolute',
                'right': 0,
                'top': '50px'
            }
        }
    },
    created() {
        this.findSearchProductTypeList();
        this.select = this.$getStorage('productType') ?? 0;
        this.city = this.$getStorage('HomeSearchCity') ?? ['全国'];
        this.typeName = this.$getStorage('typeName') ?? 'firm';
    },
    methods: {
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {};
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName
                        }
                        this.options.push(odj)
                    });
                }
            })
        },
        // 首页搜索
        search() {
            this.$removeStorage('fetchDataPage');
            this.$removeStorage('fetchDataSize');
            let typeName = this.typeName;
            if (typeName == 'firm' || typeName == 'product') {
                if ((!this.inputText)) {
                    return this.$common.message('还没有输入任何内容呢！', 'warning')
                    // return this.$router.replace('/home')
                }
            } else {
                console.log(this.inputText)
                console.log(this.city.length)
                if ((!this.inputText && this.city.length == 0)) {
                    return this.$common.message('还没有输入任何内容呢！', 'warning')
                    // return this.$router.replace('/home')
                }
            }
            // 
            if (!this.inputText && !this.city && this.$route.name != 'index') {
                return this.$router.replace('/home')
            }
            // 搜索商品
            if (this.$route.name != 'search' && typeName == 'product') {
                let historyList = this.$getStorage('historyList') != null ? this.$getStorage('historyList') : [];
                historyList.push(this.inputText);
                let List = [...new Set(historyList)];
                this.$setStorage('historyList', List)
                this.$setStorage('searchProductInput',this.inputText);
                this.$router.push({
                    name: 'search',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            // name: this.inputText,
                            type: 'text',
                            select: this.select
                        }))
                    }
                })
            } else if (this.$route.name != 'search' && (typeName == 'firm' || typeName == 'address')) {
                if (typeName == 'firm') {
                    let firmhistoryList = this.$getStorage('firmhistoryList') != null ? this.$getStorage('firmhistoryList') : [];
                    firmhistoryList.push(this.inputText);
                    let List = [...new Set(firmhistoryList)];
                    this.$setStorage('firmhistoryList', List)
                } else {
                    if (this.inputText) {
                        let addresshistoryList = this.$getStorage('addresshistoryList') != null ? this.$getStorage('addresshistoryList') : [];
                        addresshistoryList.push(this.inputText);
                        let List = [...new Set(addresshistoryList)];
                        this.$setStorage('addresshistoryList', List)
                    }
                }
                this.$setStorage('searchFirmInput',this.inputText);
                this.$setStorage('searchFirmcity',this.city);
                this.$router.push({
                    name: 'searchFirm',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            // inputText: this.inputText,
                            // city: this.city,
                            // typeName,
                            type: 'text',
                        }))
                    }
                })
            } else {
                let historyList = this.$getStorage('historyList') != null ? this.$getStorage('historyList') : [];
                historyList.push(this.inputText);
                let List = [...new Set(historyList)];
                this.$setStorage('historyList', List)
                this.$router.push({
                    name: 'search',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            name: this.inputText,
                            type: 'text',
                            select: this.select
                        }))
                    }
                })
            }
            this.showHistoryBox = false
        },
        // 获取焦点获取历史记录
        inputFocus() {
            let that = this;
            if (that.typeName == 'product') {
                let historyList = that.$getStorage('historyList') != null ? that.$getStorage('historyList') : [];
                that.historyList = historyList;
                that.showHistoryBox = true
                that.showImageUploadBox = false
            } else if (that.typeName == 'firm') {
                let firmhistoryList = that.$getStorage('firmhistoryList') != null ? that.$getStorage('firmhistoryList') : [];
                that.firmhistoryList = firmhistoryList;
                that.showHistoryBox = true
                that.showImageUploadBox = false
            } else if (that.typeName == 'address') {
                let addresshistoryList = that.$getStorage('addresshistoryList') != null ? that.$getStorage('addresshistoryList') : [];
                that.addresshistoryList = addresshistoryList;
                that.showHistoryBox = true
                that.showImageUploadBox = false
            }

        },
        //失去焦点历史记录关闭
        inputBlur() {
            setTimeout(() => {
                this.showHistoryBox = false
            }, 500);
        },
        // 历史记录查询
        historySearch(value) {
            let that = this;
            if (that.typeName == 'product') {
                this.$setStorage('searchProductInput',value);
                that.$router.push({
                    name: 'search',
                    query: {
                        key: that.$UrlEncode.encode(JSON.stringify({
                            name: value,
                            type: 'text',
                            select: that.select
                        }))
                    }
                })
            } else {
                this.$setStorage('searchFirmInput',value);
                this.$setStorage('searchFirmcity',this.city);
                that.$router.push({
                    name: 'searchFirm',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            inputText: value,
                            typeName: that.typeName,
                            type: 'text',
                        }))
                    }
                })
            }
        },
        // 清空历史记录
        deleteHistoryList() {
            let that = this;
            if (that.typeName == 'product') {
                if (!that.$getStorage('historyList')) {
                    return that.$common.message('暂无历史记录可清除！', 'warning', 1500)
                }
                that.$removeStorage('historyList')
            } else if (that.typeName == 'firm') {
                if (!that.$getStorage('firmhistoryList')) {
                    return that.$common.message('暂无历史记录可清除！', 'warning', 1500)
                }
                that.$removeStorage('firmhistoryList')
            } else if (that.typeName == 'address') {
                if (!that.$getStorage('addresshistoryList')) {
                    return that.$common.message('暂无历史记录可清除！', 'warning', 1500)
                }
                that.$removeStorage('addresshistoryList')
            }

            that.showHistoryBox = false
            that.$common.message('历史记录已清空！', 'success')
        },
        // 展开图片上传盒子
        handleImageUploadBox() {
            this.$setStorage('isreload', true);
            this.showHistoryBox = false;
            this.showImageUploadBox = true;
        },
        // 关闭上传图片盒子
        closeUploadBox(value) {
            this.showImageUploadBox = value
        },
        changeProductType(value) {
            this.$setStorage('productType', value);
            // this.selsectChange
            this.$emit('selsectChange')
        },
        // 跳转去色彩库
        goToColourSearch() {
            this.$router.push({
                name: 'Coloursearch',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        enterpriseId: '',
                    }))
                }
            })
        },
        handleClose(tag) {
            let historyList = this.historyList;
            this.historyList = historyList.splice(historyList.indexOf(tag), 1);
            this.$setStorage('historyList', historyList);
            this.showHistoryBox = true;
        },
        firmhandleClose(tag) {
            let firmhistoryList = this.firmhistoryList;
            this.firmhistoryList = firmhistoryList.splice(firmhistoryList.indexOf(tag), 1);
            this.$setStorage('firmhistoryList', firmhistoryList);
            this.showHistoryBox = true;
        },
        addresshandleClose(tag) {
            let addresshistoryList = this.addresshistoryList;
            this.addresshistoryList = addresshistoryList.splice(addresshistoryList.indexOf(tag), 1);
            this.$setStorage('addresshistoryList', addresshistoryList);
            this.showHistoryBox = true;
        },
        // 选中省市区
        handleChangeCity(value) {
            this.city = value;
            this.$setStorage('HomeSearchCity', value)
        },
        typeNameChange(value) {
            this.typeName = value;
            this.$setStorage('typeName', value);
            this.inputText = '';
        }
    },
}

</script>
<style lang='less' scoped>
.search-box {
    width: 1280px;
    position: relative;
    height: 50px;
    border-bottom: 1px solid #999999;
    padding-bottom: 20px;

    .logo {
        width: 152px;
        height: 54px;
    }

    .search {
        position: absolute;
        right: 0;
        // border: 1px solid red;

        .search-city {
            position: absolute;
            left: -200px;
            width: 190px;
            height: 40px;

            // background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            /deep/.el-input__inner {
                width: 190px;
                height: 40px;
                background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                color: #FFFFFF;
            }

            ::placeholder {
                color: #FFFFFF;
            }

            /deep/.el-input__icon {
                color: #FFFFFF;
            }
        }

        .search-options {
            position: absolute;
            top: -30px;
            left: 50px;

            .pitchOn {
                border-bottom: 3px solid #0270F2;
                padding: 5px;
                color: #333333;
                font-size: 14px;
                cursor: pointer;
            }

            .NopitchOn {
                color: #999999;
                font-size: 14px;
                padding: 5px;
                cursor: pointer;
            }
        }

        .search-input {
            position: relative;

            .select-box {
                width: 110px;
                position: absolute;
                left: 0;
            }

            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 110px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 600px;
            }

            .search-icon {
                width: 50px;
                height: 38px;
                line-height: 40px;
                text-align: center;
                line-height: 40px;
                position: absolute;
                right: 0;
                font-size: 20px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: 0;
            }

            .search-icon2 {
                width: 50px;
                height: 38px;
                line-height: 50px;
                position: absolute;
                right: 50px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: auto;
            }
        }

        .search-input2 {
            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 50px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 600px;
            }
        }

        .search-icon3 {
            width: 80px;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            color: #FFFFFF;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        }

        :deep(.el-input__inner) {
            border: 1px solid #E0E1E3;
            border-right: none !important;
        }

        .el-button {
            border: 1px solid #E0E1E3;
            border-left: none !important;
            border-radius: 0%;
        }

        el-button {
            border: none;
            margin-left: 5px;
        }
    }

    .search-container {
        position: absolute;
        top: 50px;
        right: 70px;
        z-index: 99;
        border-radius: 10px;

        .input-history {
            // width: 380px;
            border: 1px solid #e7e7e7;
            background-color: #ffffff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 1px;

            .delete-history {
                display: inline-block;
                padding: 1px 15px;
            }

            .history {
                margin-top: 5px;
                padding: 5px;
                flex-wrap: wrap;

                .history-item {
                    // border: 1px solid #000;
                    background: #ffffff;
                    border-radius: 15px;
                    // padding: 6px 10px;
                    font-size: 14px;
                    // color: #000;
                    margin: 10px 20px 0 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .history-no {
                    padding: 10px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
</style>
