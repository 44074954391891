<template>
  <div class="index">
    <HomeSearchBar @selsectChange="selsectChange"></HomeSearchBar>
    <div class="top flex">
      <!-- 轮播图 -->
      <el-carousel class="carousel" height="350px">
        <el-carousel-item v-for="(item, index) of swiperList" :key="index">
          <el-image style="width: 100%; height: 100%;cursor: pointer;" :src="item.adUrl" fit="fill"
            @click="ToAdvertisingPage(item.adType, item.connectId)"></el-image>
        </el-carousel-item>
      </el-carousel>
      <!-- 功能 -->
      <div class="feature">
        <img @click="$router.push('/ColourLibrary')" src="../assets/Standardcolorlibrary.png" alt="">
        <img @click="goToColourSearch(), $setStorage('isreload', true)" src="../assets/colorsearch.png" alt="">
        <img style="position: relative;" @click="showImageUploadBox = true, $setStorage('isreload', true)"
          src="../assets/photosearch.png" alt="">
      </div>
    </div>
    <!-- 最新入驻企业 -->
    <div class="newEnterprise">
      <div class="f-c-b title_list">
        <div class="list_left">
          <img src="../assets/newEnterprise.png" />
          <span class="text1">最新入驻企业</span>
          <span class="text2">/ new</span>
        </div>
        <div class="more" @click="tonewEnterprise()"><i class="el-icon-arrow-right"></i>更多</div>
      </div>
      <div class="enterprisecontainer" id="enterprisecontainer" @mouseenter="Stop()" @mouseleave="Up()">
        <div class="enterprisecontent flex">
          <div class="enterpriseItem flex" v-for="(item, idx) in  newEnterpriseList" :key="idx"
            @click="toEnterprise(item.enterpriseId)">
            <div class="blue_part"></div>
            <div class="right_part f-c">
              <img class="enterpriseImg" :src="item.eimage1" alt="">
              <div class="enterpriseInfo">
                <div class="enterpriseName">{{ item.enterpriseName }}</div>
                <div class="enterpriseCity">{{ item.city }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 热门商品 -->
    <div class="showList">
      <div class="f-c-b title_list">
        <div class="list_left">
          <img src="../assets/fire.png" />
          <span class="text1">热门商品</span>
          <span class="text2">/ hot</span>
        </div>
        <div class="more" @click="toMore(2)"><i class="el-icon-arrow-right"></i>更多</div>
      </div>
      <div class="f-fs maincontent">
        <div class="left" @click="toDetailPage(hotCommodity.productId)">
          <img :src="hotCommodity.img1Url" alt="">
          <div class="name">{{ hotCommodity.productName }}</div>
          <div v-if="hotCommodity.unitPrice" class="price">
            <span>{{ hotCommodity.valuationCurrency }}</span>
            <span style="font-size: 18px;">{{ hotCommodity.unitPrice }}</span>
          </div>
          <div v-else class="price">暂无报价</div>

        </div>
        <div class="f-w right">
          <div class="item" v-for="(item, idx) in  hotCommodityList.slice(0)" :key="idx"
            @click="toDetailPage(item.productId)">
            <img :src="item.img1Url" alt="">
            <div class="name">{{ item.productName }}</div>
            <div v-if="item.unitPrice" class="price">
              <span>{{ item.valuationCurrency }}</span>
              <span style="font-size: 18px;">{{ item.unitPrice }}</span>
            </div>
            <div v-else class="price">暂无报价</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 人气最高 -->
    <div class="showList">
      <div class="f-c-b title_list">
        <div class="list_left">
          <img src="../assets/popular.png" />
          <span class="text1">人气最高</span>
          <span class="text2">/ most popular</span>
        </div>
        <div class="more" @click="toMore(1)"><i class="el-icon-arrow-right"></i>更多</div>
      </div>
      <div class="f-fs maincontent">
        <div class="left" @click="toDetailPage(mostPopular.productId)">
          <img :src="mostPopular.img1Url" alt="">
          <div class="name">{{ mostPopular.productName }}</div>
          <div v-if="mostPopular.unitPrice" class="price">
            <span>{{ mostPopular.valuationCurrency }}</span>
            <span style="font-size: 18px;">{{ mostPopular.unitPrice }}</span>
          </div>
          <div v-else class="price">暂无报价</div>
        </div>
        <div class="f-w right">
          <div class="item" v-for="(item, idx) in  mostPopularList.slice(0)" :key="idx"
            @click="toDetailPage(item.productId)">
            <img :src="item.img1Url" alt="">
            <div class="name">{{ item.productName }}</div>
            <div v-if="item.unitPrice" class="price">
              <span>{{ item.valuationCurrency }}</span>
              <span style="font-size: 18px;">{{ item.unitPrice }}</span>
            </div>
            <div v-else class="price">暂无报价</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 特别推荐 -->
    <div class="showList">
      <div class="f-c-b title_list">
        <div class="list_left">
          <img src="../assets/like.png" />
          <span class="text1">特别推荐</span>
          <span class="text2">/  special recommendation</span>
        </div>
        <!-- <div class="more" @click="toMore(3)"><i class="el-icon-arrow-right"></i>更多</div> -->
      </div>
      <div class="f-fs arrivals" id="arrivals" @mouseenter="arrivalsStop()" @mouseleave="arrivalsUp()">
        <div class="arrivalsContainer flex">
          <div class="itemList" v-for="(item, index) of arrivals" :key="index">
            <div class="margin-30">
              <div class="tit f-c-b">
                <div class="f-c-c">
                  <div class="titVal">{{ item.productTypeStr }}</div>
                  <img src="../assets/right1.png" alt="">
                </div>
                <div class="tomore" @click="toMore(4,item.productType)"><i class="el-icon-arrow-right"></i>更多</div>
              </div>
              <div class="flex">
                <div class="leftItem f-cl-c" @click="toDetailPage(item.productTypeList[0].productId)">
                  <img :src="item.productTypeList[0].img1Url" alt="">
                  <div class="name">{{ item.productTypeList[0].productName }}</div>
                  <div v-if="item.productTypeList[0].unitPrice" class="price">
                    <span>{{ item.productTypeList[0].valuationCurrency }}</span>
                    <span style="font-size: 18px;">{{ item.productTypeList[0].unitPrice }}</span>
                  </div>
                  <div v-else class="price">暂无报价</div>
                </div>
                <div class="f-w rightItem">
                  <div class="item" v-for="(item1, idx) in  item.list" :key="idx"
                    @click="toDetailPage(item1.productId)">
                    <img :src="item1.img1Url" alt="">
                    <div class="name">{{ item1.productName }}</div>
                    <div v-if="item1.unitPrice" class="price">
                      <span>{{ item1.valuationCurrency }}</span>
                      <span style="font-size: 18px;">{{ item1.unitPrice }}</span>
                    </div>
                    <div v-else class="price">暂无报价</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- 新品上架 -->
      <div class="showList">
        <div class="f-c-b title_list">
          <div class="list_left">
            <img src="../assets/fire.png" />
            <span class="text1">新品上架</span>
            <span class="text2">/ new arrivals</span>
          </div>
          <div class="more" @click="toMore(3)"><i class="el-icon-arrow-right"></i>更多</div>
        </div>
        <div class="f-fs maincontent" style="overflow: auto" v-if="specialRecommendation && specialRecommendationList">
          <div class="left" @click="toDetailPage(specialRecommendation.productId)">
            <img :src="specialRecommendation.img1Url" alt="">
            <div class="name">{{ specialRecommendation.productName }}</div>
            <div v-if="specialRecommendation.unitPrice" class="price">
              <span>{{ specialRecommendation.valuationCurrency }}</span>
              <span style="font-size: 18px;">{{ specialRecommendation.unitPrice }}</span>
            </div>
            <div v-else class="price">暂无报价</div>
          </div>
          <div class="f-w right">
            <div class="item" v-for="(item, idx) in  specialRecommendationList.slice(0)" :key="idx"
              @click="toDetailPage(item.productId)">
              <img :src="item.img1Url" alt="">
              <div class="name">{{ item.productName }}</div>
              <div v-if="item.unitPrice" class="price">
                <span>{{ item.valuationCurrency }}</span>
                <span style="font-size: 18px;">{{ item.unitPrice }}</span>
              </div>
              <div v-else class="price">暂无报价</div>
            </div>
          </div>
        </div>
        <div class="f-c-c mt-50" v-else>暂无商品</div>

        <div class="hitBottom f-w" v-if="hitBottom.length != 0 && hitBottom">
          <div class="item" v-for="(item, idx) in hitBottom" :key="idx" @click="toDetailPage(item.productId)">
            <img :src="item.img1Url" alt="">
            <div class="name">{{ item.productName }}</div>
            <div v-if="item.unitPrice" class="price">
              <span>{{ item.valuationCurrency }}</span>
              <span style="font-size: 18px;">{{ item.unitPrice }}</span>
            </div>
            <div v-else class="price">暂无报价</div>
          </div>

        </div>
        <div v-if="isHitBottom && hitBottom.length != 0 && hitBottom" class="f-fr-jc more">
          <span @click="toMore(3)">加载更多<i class="el-icon-caret-bottom"></i></span>
        </div>
      </div>
    </div>
    <!-- 登录 -->
    <DialogLogin :loginBoxVisible="loginBoxVisible" @CloseLoginBox="CloseLoginBox"></DialogLogin>
    <!-- 身份 -->
    <Dialog title="选择角色" width="690px" :visible="standVisibleVisible" @closeDialog="standVisibleVisible = false"
      class="standDialog">
      <div class="f-cl-c">
        <img @click="selectStand('P')" src="@/assets/finish.png" alt="">
        <img @click="selectStand('S')" src="@/assets/supplier.png" alt="">
      </div>
    </Dialog>
    <!-- 职业 -->
    <DialogStanding :jodBoxVisible="jodBoxVisible" @CloseJobBox="CloseJobBox"></DialogStanding>
    <!-- 图片搜索 -->
    <ImageUpload @closeUploadBox="closeUploadBox" v-show="showImageUploadBox" :imageUploadTrends="imageUploadTrends"
      :key="new Date().getTime()">
    </ImageUpload>
  </div>
</template>
<script>
import HomeSearchBar from '@/components/HomeSearchBar.vue';
import { UnitAccount } from "@/utils/attributeValue1Options";
// import { common } from '../utils/common';

export default {
  name: 'index',
  inject: ['reload'],
  // mixins: [PaginationRetention],
  components: {
    HomeSearchBar,
    ImageUpload: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue')
  },
  data() {
    return {
      swiperList: [],//轮播
      newEnterpriseList: [], // 最新入驻企业
      mostPopular: {},
      mostPopularList: [],
      hotCommodity: {},
      hotCommodityList: [],
      specialRecommendation: {},
      specialRecommendationList: [],
      size: 20,
      loginBoxVisible: false, // 登录弹窗
      standVisibleVisible: false,  // 身份弹窗
      jodBoxVisible: false,  // 职业弹窗
      hitBottom: [],
      hitBottomIdex: 2,
      isHitBottom: false,
      showImageUploadBox: false,
      imageUploadTrends: {
        'position': 'absolute',
        'right': '300px',
        'top': '500px'
      },
      frompathName: '',
      isreload: false,
      intNum: null,
      arrivalsintNum: null,
      arrivals: [],
    }
  },
  created() {
    this.standVisibleVisible = this.$getStorage('spType') ? false : true;
    let InvestigateFlag = this.$getStorage('InvestigateFlag');
    this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
    this.frontPageCarouselList();
    this.findNewEnterpriseList();
    let productType = this.$getStorage('productType') ?? 0;
    this.popularProductsList(1, productType);
    this.popularProductsList(2, productType);
    this.popularProductsList(3, productType);
    this.popularProductsTypeList()
  },
  activated() {
    if (this.$getStorage('isreload')) {
      this.reload();
      this.$setStorage('isreload', false);
      this.Up();
      this.arrivalsUp();
    }
  },
  mounted() {
    if (this.$route.name == 'index') {
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    // 登陆框开始--------
    showLoginBox() {
      this.loginBoxVisible = true;

    },
    // 关闭登录弹窗
    CloseLoginBox() {
      this.loginBoxVisible = false;
      if (this.$getStorage('token')) {
        let InvestigateFlag = this.$getStorage('InvestigateFlag');
        this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
      }
    },
    // 关闭职业弹窗
    CloseJobBox() {
      this.jodBoxVisible = false;
      this.reload();
    },
    // 获取轮播信息
    frontPageCarouselList() {
      this.$http.frontPageCarouselList({
      }).then(res => {
        if (res.code == 200) {
          this.swiperList = res.data
        }
      })
    },
    // 轮播跳转
    ToAdvertisingPage(adType, connectId) {
      if (!this.$getStorage('token')) {
        return this.showLoginBox()
      }
      // adType: p--商品详情 e-企业详情 w-
      if (adType == 'p') {
        this.$router.push({
          path: '/productDetail',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              id: connectId
            }))
          }
        })
      } else if (adType == 'e') {
        this.$router.push({
          path: '/SupplierInfoIndex',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              id: connectId,
              type: 'product'
            }))
          }
        })
      } else if (adType == 'w') {
        this.getWebInfo(connectId);
      }
    },
    // 获取网页信息接口
    getWebInfo(webinfoId) {
      this.$http.getWebInfo({
        webinfoId
      }).then(res => {
        if (res.code == 200) {
          window.open(res.data.webUrl, '_blank');
        }
      })
    },
    //  获取最新入驻企业
    findNewEnterpriseList() {
      this.$http.findNewEnterpriseList({
        page: 1,
        size: 20,
      }).then(res => {
        if (res.code == 200) {
          this.newEnterpriseList = res.data.records;
          this.Up();
        }
      })
    },
    time() {
      const that = this;
      if (that.intNum) {
        clearTimeout(that.intNum);
      }
      let div = document.getElementById('enterprisecontainer');
      if (div != null) {
        that.intNum = setTimeout(() => {
          div.scrollLeft = div.scrollLeft + 1;
          if (div.scrollLeft + div.clientWidth == div.scrollWidth) {
            div.scrollLeft = 0;
          }
          that.time();
        }, 10)
      }

    },
    Stop() {
      clearTimeout(this.intNum);
      this.intNum = null;
    },
    Up() {
      this.time();
    },
    arrivalstime() {
      const that = this;
      if (that.arrivalsintNum) {
        clearTimeout(that.arrivalsintNum);
      }
      let div = document.getElementById('arrivals');
      if (div != null) {
        that.arrivalsintNum = setTimeout(() => {
          div.scrollLeft = div.scrollLeft + 1;
          if (div.scrollLeft + div.clientWidth == div.scrollWidth) {
            div.scrollLeft = 0;
          }
          that.arrivalstime();
        }, 10)
      }

    },
    arrivalsUp() {
      this.arrivalstime();
    },
    arrivalsStop() {
      clearTimeout(this.arrivalsintNum);
      this.arrivalsintNum = null;
    },
    tonewEnterprise() {
      this.$router.push({
        name: 'MoreEnterprise'
      })
    },
    // 跳转去供应商页面
    toEnterprise(enterpriseId) {
      if (!this.$getStorage('token')) {
        this.loginBoxVisible = true
      } else {
        this.$setStorage('isreload', true)
        this.$router.push({
          name: 'SupplierInfoIndex',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              id: enterpriseId,
              type: 'product',
            }))
          }
        })
      }
    },
    // 获取首页产品列表
    popularProductsList(state, productType) {
      let that = this;
      that.$http.popularProductsList({
        state,//1-热门产品 2-人气 3-特别推荐
        productType: productType ?? 0,
        page: 1,
        size: 9,

      }).then(res => {
        if (res.code == 200) {
          let { records } = res.data;
          switch (state) {
            case 1:
              that.mostPopular = records[0];
              that.mostPopularList = records.slice(1);
              break;
            case 2:
              that.hotCommodity = records[0];
              that.hotCommodityList = records.slice(1);
              break;
            case 3:
              that.specialRecommendation = records[0];
              that.specialRecommendationList = records.slice(1);
              break;
          }
        }
      })
    },
    // 跳转到商品详情
    toDetailPage(productId) {
      if (!this.$getStorage('token')) {
        // this.$emit('showLoginBox')
        this.loginBoxVisible = true
      } else {
        this.$router.push({
          name: 'ProductDetail',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              id: productId
            }))
          }
        })
      }
    },
    // 触底加载
    handleScroll() {
      // 判断是否已经滚动到页面底部
      if (this.$route.name == 'index' && ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 100))) {
        if (this.hitBottomIdex <= 11 && !this.isHitBottom) {
          this.loadMoreData(this.hitBottomIdex)
          this.hitBottomIdex = this.hitBottomIdex + 1;
        }
      }
    },
    async loadMoreData(page) {
      let that = this;
      await that.$http.popularProductsList({
        state: 3,//1-热门产品 2-人气 3-特别推荐
        page,
        size: 9,
        productType: this.$getStorage('productType')
      }).then(res => {
        if (res.code == 200) {
          let { records } = res.data;
          that.isHitBottom = page != 11 ? false : true;
          that.hitBottom = records ? that.hitBottom.concat(records) : that.hitBottom;

        }
      })
    },
    // 关闭上传图片盒子
    closeUploadBox(value) {
      this.showImageUploadBox = value
    },
    // 跳转到更多商品
    toMore(state,stateProductType) {
      this.$router.push({
        name: 'MoreGoods',
        query: {
          key: this.$UrlEncode.encode(JSON.stringify({
            state,stateProductType
          }))
        }
      })
    },
    // 跳转去色彩库
    goToColourSearch() {
      this.$removeStorage('params');
      this.$removeStorage('csState');
      this.$removeStorage('picker_color');
      this.$removeStorage('picker_colorName');
      this.$router.push({
        name: 'Coloursearch',
        query: {
          key: this.$UrlEncode.encode(JSON.stringify({
            enterpriseId: this.enterpriseId,
          }))
        }
      })
    },
    // 选择身份
    selectStand(e) {
      let that = this;
      that.$setStorage('spType', e);
      that.$store.commit('spType', e)
      that.standVisibleVisible = false;
      if (that.$getStorage('token')) {
        if (!that.$getStorage('reflush')) {
          // 刷新token
          that.$http.refreshToken({
            token: localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')) : null,
            userId: localStorage.getItem('userId') != null ? JSON.parse(localStorage.getItem('userId')) : null,
            spType: localStorage.getItem('spType') != null ? JSON.parse(localStorage.getItem('spType')) : null,
          }).then(res => {
            if (res.code == 200) {
              localStorage.setItem('token', JSON.stringify(res.data.token))
              // localStorage.setItem('tokenDate', JSON.stringify(common.timeToYMD()));
              let timeUpdate = new Date().getTime();// 获取当前的时间戳
              localStorage.setItem('timeUpdate', timeUpdate);
              // this.$setStorage('timeUpdate', timeUpdate);
              if (res.data.spType == 'S' && res.data.supplyInvestigateFlag != 'N') {
                that.$setStorage('IsShowinvitationCode', res.data.supplyInvestigateFlag == "I" ? true : false);
                that.$setStorage('InvestigateFlag', res.data.supplyInvestigateFlag);
                that.jodBoxVisible = true;
              } else if (res.data.spType == 'P' && res.data.procureInvestigateFlag != 'N') {
                that.$setStorage('IsShowinvitationCode', res.data.procureInvestigateFlag == "I" ? true : false);
                that.$setStorage('InvestigateFlag', res.data.procureInvestigateFlag);
                that.jodBoxVisible = true;
              }
            }
          })
        }
      }
    },
    // 刷新token
    refreshToken() {
      this.$http.refreshToken({
        token: localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')) : null,
        userId: localStorage.getItem('userId') != null ? JSON.parse(localStorage.getItem('userId')) : null,
        spType: localStorage.getItem('spType') != null ? JSON.parse(localStorage.getItem('spType')) : null,
      }).then(res => {
        if (res.code == 200) {
          this.$setStorage('token', res.data.token);
          localStorage.setItem('token', JSON.stringify(res.data.token));
          // localStorage.setItem('tokenDate', JSON.stringify(common.timeToYMD()));
          let timeUpdate = new Date().getTime();// 获取当前的时间戳
          localStorage.setItem('timeUpdate', timeUpdate);
          // this.$setStorage('timeUpdate', timeUpdate);
        }
      })
    },
    selsectChange() {
      let productType = this.$getStorage('productType'),
        that = this;
      that.mostPopular = {};
      that.mostPopularList = [];
      that.hotCommodity = {};
      that.hotCommodityList = [];
      that.specialRecommendation = {};
      that.specialRecommendationList = [];
      that.hitBottom = [];
      that.hitBottomIdex = 2;
      that.isHitBottom = false;
      that.popularProductsList(1, productType);
      that.popularProductsList(2, productType);
      that.popularProductsList(3, productType);
    },
    // 热门商品分类列表接口
    popularProductsTypeList() {
      let that = this;
      that.$http.popularProductsTypeList({
        state: 0,
        page: 1,
        size: 5,

      }).then(res => {
        if (res.code == 200) {
          let { data } = res;
          data.forEach((item) => {
            item.productTypeStr = UnitAccount.getproductType(item.productType)
            item.list = item.productTypeList.slice(1);
          })
          this.arrivals = data;
          this.arrivalsUp();
        }
      })
    }
  },
  beforeDestroy() {
    clearTimeout(this.intNum);
    clearTimeout(this.arrivalsintNum);
    this.intNum = null;
    this.arrivalsintNum = null;
    this.Stop();
    window.removeEventListener('scroll', this.handleScroll, false)
    this.$removeStorage('fetchDataPage');
    this.$removeStorage('fetchDataSize');

  },

}
</script>
<style lang="less">
.index {
  overflow: auto;

  .top {
    margin-top: 20px;

    .carousel {
      height: 350px;
      width: 945px;

    }

    .feature {
      height: 350px;
      width: 320px;
      margin-left: 15px;

      img {
        width: 320px;
        height: 110px;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }

  .newEnterprise {

    margin-top: 40px;

    .title_list {
      border-bottom: 1px solid #999999;
      padding-bottom: 20px;

      .list_left {
        img {
          margin-right: 5px;
          width: 28px;
          height: 25px;
        }

        .text1 {
          color: #333333;
          font-size: 28px;
          margin-right: 15px;
        }

        .text2 {
          color: #999999;
          font-size: 16px;
        }

      }

      .more {
        color: #B1B1B1;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .enterprisecontainer {
      width: 1280px;
      overflow-x: scroll;

      .enterprisecontent {
        margin-top: 24px;
        width: 8600px;
        height: 160px;

        .enterpriseItem {
          width: 420px;
          height: 160px;
          border-radius: 10px;
          margin-right: 10px;
          cursor: pointer;

          .blue_part {
            width: 10px;
            height: 160px;
            background-color: #7CB3F5;
            border-radius: 10px 0 0 10px;
          }

          .right_part {
            width: 410px;
            height: 160px;
            border-radius: 0 10px 10px 0;
            background-color: #F8F8F8;
            padding: 0 20px;

            .enterpriseImg {
              height: 128px;
              width: 128px;
              border-radius: 10px;
            }

            .enterpriseInfo {
              margin-left: 20px;

              .enterpriseName {
                font-size: 22px;
                font-weight: 400;
                color: #333333;
              }

              .enterpriseCity {
                margin-top: 30px;
                font-size: 16px;
                font-weight: 400;
                color: #999999;
              }
            }
          }
        }

      }
    }

    .enterprisecontainer::-webkit-scrollbar {
      display: none;
    }
  }

  .showList {
    margin-top: 40px;
    width: 1280px;

    .title_list {
      border-bottom: 1px solid #999999;
      padding-bottom: 20px;

      .list_left {
        img {
          margin-right: 5px;
          width: 20px;
          height: 25px;
        }

        .text1 {
          color: #333333;
          font-size: 28px;
          margin-right: 15px;
        }

        .text2 {
          color: #999999;
          font-size: 16px;
        }

      }

      .more {
        color: #B1B1B1;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .maincontent {
      margin-top: 40px;
      width: 1280px;

      .left {
        padding-top: 10px;
        width: calc(20% - 10px);
        height: 525px;
        background-color: #F6F8FB;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          transform: translateY(-3px);
        }

        .name {
          margin-top: 40px;
          color: #333333;
          font-size: 18px;
          text-align: center;
        }

        .price {
          margin-top: 25px;
          font-size: 14px;
          color: #FF4E36;
          text-align: center;
        }

        img {
          // margin-top: 45px;
          margin-left: 10px;
          border-radius: 10px;
          width: 225px;
          height: 320px;
        }
      }

      .right {
        width: 80%;

        .item {
          padding-top: 10px;
          width: calc(25% - 10px);
          height: 260px;
          background-color: #F6F8FB;
          border-radius: 10px;
          margin-left: 10px;
          margin-bottom: 5px;
          cursor: pointer;

          &:hover {
            transform: translateY(-3px);
          }

          img {
            margin-left: 12px;
            width: 225px;
            height: 170px;
            border-radius: 10px;
          }

          .name {
            margin-top: 20px;
            color: #333333;
            font-size: 14px;
            text-align: center;
            font-weight: 400;
          }

          .price {
            margin-top: 10px;
            color: #FF4E36;
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }

    .arrivals {
      margin-top: 40px;
      width: 1280px;
      overflow-x: scroll;
      // overflow: auto;

      .arrivalsContainer {
        width: 7596px;
        // overflow-x: scroll;

        .itemList {
          width: 824px;
          height: 622px;
          background: url(../assets/bg3.png) no-repeat;
          margin-right: 20px;

          .tit {
            margin-bottom: 14px;

            .titVal {
              font-weight: 500;
              font-size: 24px;
              color: #333333;
              margin-right: 20px;
            }

            .tomore {
              cursor: pointer;
              span {
                font-size: 18px;
                cursor: pointer;
                color: #999999;
              }

            }
          }

          .leftItem {
            width: 284px !important;
            height: 525px;
            background-color: #fff;
            border-radius: 10px;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
              transform: translateY(-3px);
            }

            .name {
              margin-top: 40px;
              color: #333333;
              font-size: 18px;
              text-align: center;
            }

            .price {
              margin-top: 25px;
              font-size: 14px;
              color: #FF4E36;
              text-align: center;
            }

            img {
              margin-top: 40px;
              margin-left: 10px;
              border-radius: 10px;
              width: 225px;
              height: 320px;
            }
          }

          .rightItem {
            width: 506px;

            .item {
              padding-top: 10px;
              // width: 240px;
              width: calc(50% - 10px);
              height: 260px;
              background-color: #F6F8FB;
              border-radius: 10px;
              margin-bottom: 5px;
              cursor: pointer;
              margin-right: 5px;

              &:hover {
                transform: translateY(-3px);
              }

              img {
                margin-left: 12px;
                width: 200px;
                height: 170px;
                border-radius: 10px;
              }

              .name {
                margin-top: 20px;
                color: #333333;
                font-size: 14px;
                text-align: center;
                font-weight: 400;
              }

              .price {
                margin-top: 10px;
                color: #FF4E36;
                text-align: center;
                font-size: 14px;
              }
            }
          }

        }

      }
    }

    .arrivals::-webkit-scrollbar {
      display: none;
    }


    .hitBottom {
      width: 100%;

      .item {
        padding-top: 10px;
        width: calc(20% - 10px);
        height: 260px;
        background-color: #F6F8FB;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
          transform: translateY(-3px);
        }

        img {
          margin-left: 12px;
          width: 225px;
          height: 170px;
          border-radius: 10px;
        }

        .name {
          margin-top: 20px;
          color: #333333;
          font-size: 14px;
          text-align: center;
          font-weight: 400;
        }

        .price {
          margin-top: 10px;
          color: #FF4E36;
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .more {
      margin-top: 30px;

      // border: 1px solid red;
      span {
        font-size: 18px;
        cursor: pointer;
        color: #999999;
      }

    }
  }

  .standDialog {
    .el-dialog__headerbtn {
      display: none;
    }

    span {
      color: #333333;
      font-size: 30px;
    }

    img {
      cursor: pointer;
    }
  }

  .jodDialog {
    .el-dialog__headerbtn {
      display: none;
    }

    span {
      color: #333333;
      font-size: 30px;
    }

    .tip {
      font-size: 16px;
      color: #333333;
    }

    .btns {
      padding: 16px 30px;
      font-size: 16px;
      text-align: center;
      border-radius: 20px;
      box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
      margin-right: 20px;
      margin-top: 20px;
      cursor: pointer;
    }

    .select {
      background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
      color: #FFF;
      padding: 16px 30px;
      font-size: 16px;
      text-align: center;
      border-radius: 20px;
      box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
      margin-right: 20px;
      margin-top: 20px;
      cursor: pointer;
    }

    .invitationCode {
      margin-top: 20px;

      .title {
        font-size: 16px;
        margin-right: 30px;
      }

      .el-input {
        width: 400px !important;
        height: 60px;
        border: none;
      }

      .el-input__inner {
        height: 60px;
        border: none;
        box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
        padding: 10px 20px;
      }
    }

    .verifyBtn {
      margin: 20px auto 0;
      border-radius: 10px;
      background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
      height: 60px;
      width: 160px;
      text-align: center;
      line-height: 60px;
      color: #FFF;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>